<template>
	<div v-if="authed" @click.stop="fullImageSrc=''">
        <!--<v-toolbar class="pt-2">-->
		<v-form>
			<div class="pl-4 pt-3 pb-1">
				<v-row>
					<v-col cols="12" md="3">
						<v-text-field label="病案号" v-model="pid" dense clearable hide-details
							append-icon="search" @click:append="searchById" @keyup.enter.native="searchById"/>
					</v-col>
					<v-col cols="12" md="3">
						<v-text-field label="姓名" v-model="pname" dense clearable hide-details
							append-icon="search" @click:append="searchByName" @keyup.enter.native="searchByName"/>
					</v-col>
					<v-col cols="12" md="3">
						<v-menu
							v-model="menu1"
							:close-on-content-click="false"
							:nudge-right="10"
							transition="scale-transition"
							offset-y
							min-width="290px"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									v-model="pdob"
									label="出生日期"
									readonly
									v-bind="attrs"
									v-on="on"
									dense clearable hide-details
									append-icon="search"
									@click:append="searchByDOB"
								></v-text-field>
							</template>
							<v-date-picker
								v-model="pdob"
								@input="menu1 = false"
								no-title
								scrollable
							></v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="12" md="3">
						<v-menu
							v-model="menu2"
							:close-on-content-click="false"
							:nudge-right="10"
							transition="scale-transition"
							offset-y
							min-width="290px"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									v-model="pdate"
									label="手术日期"
									readonly
									v-bind="attrs"
									v-on="on"
									dense clearable hide-details
									append-icon="search"
									@click:append="searchByDate"
								></v-text-field>
							</template>
							<v-date-picker
								v-model="pdate"
								@input="menu2 = false"
								no-title
								scrollable
							></v-date-picker>
						</v-menu>
					</v-col>
				</v-row>
				</div>
		</v-form>
		<v-divider/>
		<v-data-table :headers="headers" :items="items" item-key="DJH1" v-model="selected"
			:loading="loading" loading-text="加载中...">
            <template v-slot:item.imagesrc="{ item }">
                <v-icon v-if="item.imagesrc" class="mr-2" @click.stop="fullImageSrc=item.imagesrc">preview</v-icon>
            </template>
		</v-data-table>
		<v-overlay :value="!!fullImageSrc">
			<img :src="fullImageSrc" @click.stop="fullImageSrc=''" style="max-height:100vh"></img>
		</v-overlay>
    </div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
    import {formatTime, optstr, calculateAge} from '../utils'

    export default {
        data() {
            return {
				authed: false,
				pid: '',
				pname: '',
				pdate: '',
				pdob: '',
				menu1: false,
				menu2: false,
                headers: [
                    {text:'病案号', value:'PatientID', width:120},
                    {text:'姓名', value:'XM1', width:120},
                    {text:'性别', value:'XB1', width:80},
                    {text:'出生日期', value:'DOB', width:120},
                    {text:'项目', value:'SQMD', width:120},
                    {text:'手术医生', value:'StudyUser', width:120},
                    {text:'日期', value:'ScheduleDate', width:120},
                    {text:'时间', value:'StudyTime', width:120},
                    {text:'查看报告', value:'imagesrc', width:100},
                ],
				items: [],
				files: [],
                loading: false,
				selected: [],
                fullImageSrc: '',
            }
        },
        mounted() {
			this.authed = this.$hasPrivilege('内镜报告');
			if (!this.authed) return;
			this.formatTime = formatTime;
		},
		methods: {
			async search(q) {
				this.loading = true;
				this.items = await this.$getEdReports(q);
				if (this.items.length === 0) {
					this.$dialog.message.info('无符合条件的记录');
				}
				this.loading = false;
			},
			searchById() {
				if (!this.pid) return;
				this.search({pid: this.pid});
			},
			searchByName() {
				if (!this.pname) return;
				this.search({pname: this.pname});
			},
			searchByDOB() {
				if (!this.pname || !this.pdob) return;
				this.search({username: this.pname, dob: this.pdob});
			},
			searchByDate() {
				if (!this.pdate) return;
				this.search({pdate: this.pdate});
			}
		}
    }
</script>
